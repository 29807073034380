import { useState, useEffect } from 'react'
import isBrowser from '@modules/isBrowser'

const getWindowDimensions = () => {
  let width
  let height

  if (isBrowser()) {
    width = window.innerWidth
    height = window.innerHeight
  } else {
    width = 0
    height = 0
  }

  return {
    width,
    height
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
